<template>
  <div class="ui-orders">
    <header class="ui-orders__header">
      <CRow class="align-items-center h-100">
        <CCol sm="auto" class="mt-2 mb-1 my-sm-0">
          <CButton color="outline-primary" size="sm" @click="onCancel()">
            <font-awesome-icon icon="arrow-left" />
          </CButton>
        </CCol>

        <CCol sm="auto" class="mt-2 mb-1 my-sm-0 mr-auto">
          <div class="d-flex align-items-center">
            <figure class="m-0 mr-3" v-if="makertLogoImage()">
              <img
                :src="makertLogoImage()"
                alt=""
                class="border rounded-circle"
                width="50"
                height="50"
              />
            </figure>
            <div class="ui-type-display-lg">
              {{ $t("role.orders.rounds.title") }}
            </div>
          </div>
        </CCol>

        <CCol sm="auto" class="mt-2 mb-1 my-sm-0">
          <OrdersPrints
            v-if="round"
            :prints="round.prints"
            :title="$t('role.orders.rounds.printBtn')"
          />
        </CCol>

        <CCol sm="auto" class="mt-1 mb-2 my-sm-0">
          <CDropdown class="ui-orders-switch">
            <template #toggler-content>
              <div class="ui-orders-switch__label">
                <div class="ui-orders-switch__item text-primary">
                  <font-awesome-icon icon="calendar" />
                </div>
                <div class="ui-orders-switch__item">
                  {{ $t("role.orders.rounds.orderClosures.title") }}
                  <br />
                  <strong>
                    {{ round !== null ? roundLabel(round) : "---" }}
                  </strong>
                </div>
                <div class="ui-orders-switch__item">
                  {{ $t("role.orders.rounds.orderClosures.products") }}
                  <br />
                  <strong>{{
                    round !== null ? roundProductCount(round) : "---"
                  }}</strong>
                </div>
              </div>
            </template>
            <CDropdownItem
              v-for="r in rounds"
              v-bind:key="r.id"
              @click.prevent="selectRound(r)"
              :active="round && round.id === r.id"
              >{{ roundLabel(r) }}</CDropdownItem
            >
          </CDropdown>
        </CCol>
      </CRow>
    </header>
    <section class="ui-orders__body">
      <div class="d-flex justify-content-center" v-if="loading">
        <CSpinner color="info" />
      </div>
      <div class="ui-orders__wrapper-1" v-if="round">
        <OrdersRoundFilter />
        <OrdersRoundNav />
        <OrdersRoundView />
        <OrdersIncidentModal />
        <OrdersIncidentEditModal />
        <OrdersWeightModal />
        <OrdersIncidentMessageModal />
      </div>
    </section>
  </div>
</template>

<script>
import get from "lodash/get";
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../ds";
import { mapState } from "vuex";
import { formatDate } from "../../../../helpers/common";
import { OrdersRoundFilter, OrdersRoundNav, OrdersRoundView } from "./partials";
import OrdersIncidentModal from "../share/OrdersIncidentModal";
import OrdersIncidentEditModal from "../share/OrdersIncidentEditModal";
import OrdersPrints from "../share/OrdersPrints";
import OrdersWeightModal from "../share/OrdersWeightModal";
import OrdersIncidentMessageModal from "../share/OrdersIncidentMessageModal";

export default {
  name: "OrdersRounds",

  components: {
    OrdersRoundFilter,
    OrdersRoundNav,
    OrdersRoundView,
    OrdersPrints,
    OrdersIncidentModal,
    OrdersIncidentEditModal,
    OrdersWeightModal,
    OrdersIncidentMessageModal,
  },

  data() {
    return {
      loading: false,
      rounds: [],
    };
  },

  computed: {
    ...mapState("rounds", ["round"]),
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const roleId = this.$store.state.role.id;
      this.$store.commit("rounds/resetState");
      this.$store.commit("incidents/resetState");

      const self = this;
      const { roundId } = this.$route.params;
      const dm = GetDataManagerNew("role_market_orders_round", [roleId]);
      const query = new Query().where("close", "equal", false);
      // const query = new Query().sortBy("time_limit", "descending").take(10);

      self.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          self.rounds = response.result;
          if (roundId) {
            const round = self.rounds.find((r) => r.id == roundId);
            if (round) {
              self.$store.commit("rounds/setRound", round);
            }
          } else if (self.rounds.length > 0) {
            self.$store.commit("rounds/setRound", self.rounds[0]);
            this.$router.push({
              name: "RoleOrdersRounds",
              params: {
                id: roleId,
                roundId: self.rounds[0].id,
              },
            });
          }
          self.loading = false;
        })
        .catch((response) => {
          console.error(response);
          self.$store.dispatch("toaster/add", {
            title: $t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          self.loading = false;
        });
    },

    selectRound(round) {
      this.$store.commit("rounds/setRound", round);
    },

    roundLabel(round) {
      return formatDate(new Date(round.time_limit), "EEE dd/MM @ HH::mm");
    },

    roundProductCount(round) {
      return round.count_prods;
    },

    makertLogoImage() {
      const square = get(this.$store, "state.role.logo_square.image_thumb");
      const rect = get(this.$store, "state.role.logo_rect.image_thumb");
      return square ? square : rect;
    },

    onCancel() {
      this.$router.push({
        name: "RoleOrdersRoundsList",
        params: { id: this.$store.state.role.id },
      });
    },
  },
};
</script>
